import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Grid, Paper, Box, TextField } from '@mui/material';
import theme from './theme';
import emailjs from 'emailjs-com';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
}));

export default function Contact() {

    const [text, setText] = React.useState('');
    const [from_firstname, setFirstName] = React.useState('')
    const [from_email, setEmail] = React.useState('')
    const [from_lastname, setLastName] = React.useState('')
    const [from_mobile, setMobile] = React.useState('')

    const template = process.env.REACT_APP_EMAIL_TEMPLATE;
    const service = process.env.REACT_APP_EMAIL_SERVICE;
    const user = process.env.REACT_APP_EMAIL_USER;

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            from_email: from_email,
            from_firstname: from_firstname,
            from_lastname: from_lastname,
            from_name: from_firstname + " " + from_lastname,
            from_mobile: from_mobile,
            message: text,
        };

        emailjs.send(service, template, templateParams, user)
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                alert('Email sent successfully!');
            }, (error) => {
                console.error('Error sending email:', error);
                alert('Error sending email. Please try again.');
            });
    };

    return (
        <Box id="Contact Us" sx={{
            flexGrow: 1, py: 7,
            backgroundColor: theme.colors.lightBeige,
        }}>
            <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 12 }}>
                <Grid item xs={12}>
                    <Item variant='none' sx={{
                        borderRadius: 0,
                        borderBottom: 0,
                        backgroundColor: theme.colors.lightBeige
                    }}>
                        <Typography variant='h1' sx={{
                            pt: 0, pb: 3, fontSize: theme.fontSizes.sectionHeading,
                            fontFamily: theme.fonts.sectionHeading,
                            color: theme.colors.black,
                            fontWeight: 400
                        }}>
                            Contact Us.
                        </Typography>
                        <TextField
                            required
                            id="outlined-required"
                            label="First Name"
                            size="small"
                            value={from_firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                            sx={{
                                width: { xs: 9 / 10, md: 3 / 4 },
                                m: 1,
                                height: '2em',
                                maxWidth: 500,
                                borderRadius: 0,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 0,
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                            }}
                        /><br />
                        <TextField
                            required
                            id="outlined-required"
                            label="Last Name"
                            size="small"
                            value={from_lastname}
                            onChange={(e) => setLastName(e.target.value)}
                            sx={{
                                width: { xs: 9 / 10, md: 3 / 4 },
                                m: 1,
                                height: '2em',
                                maxWidth: 500,
                                borderRadius: 0,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 0,
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                            }}
                        /><br />
                        <TextField
                            required
                            id="outlined-required"
                            label="Email"
                            size="small"
                            value={from_email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{
                                width: { xs: 9 / 10, md: 3 / 4 },
                                m: 1,
                                height: '2em',
                                maxWidth: 500,
                                borderRadius: 0,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 0,
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                            }}
                        /><br />
                        <TextField
                            id="outlined-number"
                            label="Number"
                            type="number"
                            size="small"
                            slotProps={{
                                inputLabel: {
                                    shrink: true,
                                },
                            }}
                            value={from_mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            sx={{
                                width: { xs: 9 / 10, md: 3 / 4 },
                                m: 1,
                                height: '2em',
                                maxWidth: 500,
                                borderRadius: 0,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 0,
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                            }}
                        /><br />
                        <TextField
                            id="outlined-multiline-static"
                            label="Message"
                            multiline
                            rows={4}
                            size="small"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            sx={{
                                width: { xs: 9 / 10, md: 3 / 4 },
                                m: 1,
                                maxWidth: 500,
                                borderRadius: 0,
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 0,
                                    '&.Mui-focused fieldset': {
                                        borderColor: 'black',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                            }}
                        /><br />
                        <Button
                            variant="contained"
                            onClick={(e) => {handleSubmit(e)}}
                            sx={{
                                width: { xs: 9 / 10, md: 3 / 4 },
                                m: 1,
                                maxWidth: 500,
                                height: '40px',
                                py: '8.5px',
                                px: '14px',
                                textAlign: 'center',
                                backgroundColor: theme.colors.olive,
                                borderRadius: 0,
                                '&:hover': {
                                    backgroundColor: theme.colors.black,
                                },
                            }}
                        >
                            Send Message
                        </Button>

                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}